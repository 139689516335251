<template>
  <div class="box">
    <el-form :model="info" label-position="left" :rules="rules" ref="ruleForm" label-width="80px" size="small">
      <el-form-item label="选择门店" prop="shop_id" v-if="shopList && shopList.length>0">
        <el-select v-model="info.shop_id" placeholder="请选择门店" size="small" style="width: 100%">
          <el-option
              v-for="item in shopList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="姓名" prop="nickname">
        <el-input v-model="info.nickname" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-input v-model="info.mobile" placeholder="请输入手机号" maxlength="11"></el-input>
      </el-form-item>
      <el-form-item label="入职日期" prop="join_time">
        <el-date-picker class="mg-rt-10"
            v-model="info.join_time"
            type="date"
            placeholder="入职日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input type="textarea" rows="4" v-model="info.remark" placeholder="请输入备注"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import util from "@/utils/util";
export default {
  data() {
    return {
      info: {
        id: '',
        shop_id: '',
        nickname: '',
        mobile: '',
        remark: '',
        join_time: ''
      },
      rules: {
        shop_id: [
          {required: true, message: '请选择门店', trigger: 'blur'}
        ],
        nickname: [
          {required: true, message: '请填写姓名', trigger: 'blur'}
        ],
      }
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  props: {
    shopList: Array,
    staffInfo: Object
  },
  watch: {
    'staffInfo': {
      handler (newValue, oldValue) {
        if(newValue) {
          this.info = newValue
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    submitForm(formName) {
      var _this = this
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          var param = JSON.parse(JSON.stringify(this.info))
          param.join_time = util.formatDate(param.join_time)
          console.log(param)
          this.$api.operate.staffAdd(param,function (res) {
            if(res.errcode == 0) {
              _this.success(res.errmsg)
              _this.$emit('callFunc');
            } else {
              _this.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
</style>
