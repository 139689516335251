import { render, staticRenderFns } from "./staff.vue?vue&type=template&id=c9efb2de&scoped=true&"
import script from "./staff.vue?vue&type=script&lang=js&"
export * from "./staff.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9efb2de",
  null
  
)

export default component.exports